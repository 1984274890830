<template>
  <section class="section music-preview">
    <div class="cover">
      <img src="/assets/images/how-it-works/WTC front cover.png" />
    </div>
    <div class="music">
      <img
        class="part-one"
        :src="`/assets/images/how-it-works/${images[1].small}`"
        @click="openImageModal(1)"
      />
      <img
        class="part-two"
        :src="`/assets/images/how-it-works/${images[2].small}`"
        @click="openImageModal(2)"
      />
      <img
        class="part-three"
        :src="`/assets/images/how-it-works/${images[3].small}`"
        @click="openImageModal(3)"
      />
      <img
        class="part-four"
        :src="`/assets/images/how-it-works/${images[4].small}`"
        @click="openImageModal(4)"
      />
      <b-modal
        v-model="imageViewModal"
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
      >
        <img
          class="big-image"
          :src="`/assets/images/how-it-works/${bigImageToShow}`"
        />
      </b-modal>
    </div>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      imageViewModal: false,
      images: {
        1: {
          small: 'I 20 A minor 03 Part I Clarinet Trumpet-1-Small.png',
          large: 'I 20 A minor 03 Part I Clarinet Trumpet-1.png',
        },
        2: {
          small: 'I 20 A minor 09 Part II Alto Saxophone-1-Small.png',
          large: 'I 20 A minor 09 Part II Alto Saxophone-1.png',
        },
        3: {
          small:
            'I 20 A minor 12 Part III Bass Clarinet Tenor Saxophone-1-Small.png',
          large: 'I 20 A minor 12 Part III Bass Clarinet Tenor Saxophone-1.png',
        },
        4: {
          small: 'I 20 A minor 19 Part IV Bass Clarinet-1-Small.png',
          large: 'I 20 A minor 19 Part IV Bass Clarinet-1.png',
        },
      },
      bigImageToShow: undefined,
    };
  },
  methods: {
    openImageModal: function (imagePart) {
      this.bigImageToShow = this.images[imagePart].large;
      this.imageViewModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
$box-shadow: 0px 3px 6px #00000029;
$scale: scale(203%);
$transition: all 100ms linear;

.music-preview {
  display: grid;
  grid-template-columns: [cover] 1fr [music] 1fr;
  column-gap: 24px;
  padding: 20px !important;

  .cover {
    box-shadow: 0px 3px 36px #00000029;
  }

  .music {
    grid-column: music;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 16px;
    row-gap: 4px;
    cursor: pointer;

    .part-one {
      transition: $transition;
      box-shadow: $box-shadow;
      grid-row: 1;
      grid-column: 1;
      &:hover {
        transform-origin: 0 0;
        transform: $scale;
      }
    }
    .part-two {
      transition: $transition;
      box-shadow: $box-shadow;
      grid-row: 1;
      grid-column: 2;
      &:hover {
        transform-origin: 100% 0;
        transform: $scale;
      }
    }
    .part-three {
      transition: $transition;
      box-shadow: $box-shadow;
      grid-row: 2;
      grid-column: 1;
      &:hover {
        transform-origin: 0 100%;
        transform: $scale;
      }
    }
    .part-four {
      transition: $transition;
      box-shadow: $box-shadow;
      grid-row: 2;
      grid-column: 2;
      &:hover {
        transform-origin: 100% 100%;
        transform: $scale;
      }
    }
  }
}

.big-image {
  display: block;
  margin: auto;
  height: calc(100vh - #{$gap * 2});
}
</style>
