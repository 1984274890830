<template>
  <div id="partsgrid">
    <div v-for="part in parts" :key="part.label">
      <h2 class="title">{{ part.label | titleize }}s</h2>
      <div class="columns">
        <div
          class="message column"
          v-for="(partRom, partNum) in part.parts"
          :key="partNum"
        >
          <div class="message-header">
            <p>Part {{ partNum | numberToRoman }}</p>
          </div>
          <div class="message-body">
            <ul>
              <li v-for="(partInst, index) in partRom" :key="index">
                <span
                  v-html="
                    $options.filters.displayInstrumentName(
                      $store.getters.instrument(partInst)
                    )
                  "
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPartsGrid',
  computed: {
    parts: {
      get: function () {
        return this.$store.state.parts;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles.scss';

.message-body {
  font-size: 14px;
}
h2.title {
  color: $gray;
  border-bottom: none;
  font-weight: 300;
}
.message.column {
  margin-bottom: 24px;
  border: solid 1px $gray-medium;
  margin-left: 6px;
  margin-right: 6px;
}
</style>
