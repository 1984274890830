<template>
  <div class="container" id="about">
    <h1 class="title">
      About the <span class="super-bold">Well-Tempered Consort</span>
    </h1>
    <h2 class="subtitle">
      <em>The Well-Tempered Clavier</em> by Johann Sebastian Bach
      (1685&ndash;1750)<br />arranged for flexible ensembles by Justin P. Tokke
    </h2>
    <div>
      <div>
        <p>
          Johann Sebastian Bach’s <em>Das Wohltemperirte Clavier</em> (<em
            >The Well-Tempered Clavier</em
          >) has endured as a monumental cornerstone of musical history. Within
          the 48 prelude and fugue sets across two books in every key, major and
          minor, is some of the most intricate and beautiful and profound music
          ever written. “The 48” have been studied by piano students for over
          two centuries since its composition in 1722 for Book I and 1744 for
          Book II. Immeasurable composers and performers have studied its
          counterpoint and used the set as a foundation for their own artistic
          contributions.
        </p>

        <about-cover-parts-prev class="preview" />
      </div>
      <p>
        However, there has always been a caveat to this collection. They are
        written for the keyboard. What does the flutist, trumpeter, or violinist
        do? They have their own repertoire, of course, but it is a shame that
        they are locked out of working and learning this collection because of
        their instrument of choice. This series, the
        <em>Well-Tempered Consort</em> attempts to solve that problem, by
        arranging the entirety of <em>The Well-Tempered Clavier</em> into
        flexible ensembles of monophonic instruments. Any instrumentalist and a
        few of their friends can get together and perform this music, relishing
        in the gorgeous harmony and counterpoint no matter if they play flute or
        tuba. One of the best qualities of Bach’s music is that it tends to
        sound good on any instrument, so this series takes that concept and runs
        with it full force!
      </p>
      <div>
        <p>
          This edition is not meant to be an Urtext or even Urtext-adjacent. It
          is intended for modern players and as such, some compromises were made
          to make the music more practical for a chamber music setting. Slurs
          and other articulations have been added where they made sense
          musically. Dynamics have also been added to aid in ensemble cohesion.
          Tempos and metronome markings have been added. In addition, grace
          notes and other ornaments have been written out in rhythm. All of
          these measures will assist players who are not familiar with Baroque
          performance practice. None of these editorial additions are present in
          Bach’s manuscript, which was written for keyboard instruments with no
          dynamic variation. Should the players wish, they can feel free to
          discard the expression markings and formulate their own
          interpretations.
        </p>
        <div class="pull-quote">
          <h2 class="subtitle">
            Any instrumentalist and a few of their friends can get together and
            perform this music, relishing in the gorgeous harmony and
            counterpoint no matter if they play flute or tuba.
          </h2>
        </div>
      </div>
      <p>
        {{ `One\xa0compromise` }} that has not been made, importantly, is the
        keys of the pieces. Every Prelude and Fugue is retained in its original
        key. A few of them have been enharmonically transposed to a more common
        key signature (such as D<span class="bravura-text flat"></span> major’s
        five flats instead of C<span class="bravura-text sharp"></span> major’s
        seven sharps). Every instrument’s transpositions are accounted for so
        extreme keys are placed in the best key possible for easy reading.
        Obviously not all instruments can play in every register so when
        appropriate, the octaves have been changed to fit the ranges of each
        instrument. Most combinations should be successful though the ensemble
        should be careful to balance well, especially in the fugues where each
        voice is coequal to the others.
      </p>
      <p>
        Since each Part (indicated by the roman numerals) can be played by
        multiple instruments, it is possible to have multiple instruments play
        the same Part simultaneously. This allows the arrangements to flexibly
        scale up to a large ensemble such as a concert band or orchestra.
        Occasionally there are small notes marked as “div. if multiple” where
        two different instruments can play different parts. This is useful in
        filling out the harmony where Bach was not strict with the number of
        voices he utilized. An additional feature: particularly in Brass parts,
        extra octaves are sometimes added to indicate an easier octave should
        the range be too extreme for the player, or if the instrument does not
        have the needed valved range extensions.
      </p>
      <p>
        On the cover of Book I’s autograph, Bach writes “Zum Nutzen und Gebrauch
        der Lehrbegierigen Musicalischen Jugend” or “For the benefit and use of
        the musical youth eager to learn.” There is no doubt to the benefits for
        study and joyous fulfillment of listening to and learning these pieces;
        it makes all who study them better musicians. I hope that you get some
        fresh enjoyment from this music and it is to your benefit!
      </p>
      <p>&mdash; Justin P. Tokke, Editor (2021)</p>
    </div>
    <h1 class="title">Parts Grid</h1>
    <h2 class="subtitle">
      This lists all the instruments provided for each Part in each size of
      ensemble.
    </h2>
    <AboutPartsGrid />
  </div>
</template>

<script>
import AboutPartsGrid from '@/components/About/AboutPartsGrid.vue';
import AboutCoverPartsPrev from '@/components/About/AboutCoverPartsPrev';

export default {
  components: {
    AboutPartsGrid,
    AboutCoverPartsPrev,
  },
  name: 'About',
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles.scss';
#about {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  .preview {
    float: right;
    max-width: 600px;
  }
  .pull-quote {
    float: left;
    width: 300px;
    padding: 16px;
    text-align: center;
    h2 {
      color: $purple;
    }
  }
}
p {
  margin-bottom: 1em;
}
</style>
